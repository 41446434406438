/* MAIN 
*******************************************************************/

.main-wrapper {
  .main-section {
    height: 100vh;
    position: relative;
    .center-section {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      .hello-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .hello {
          font-family: 'Space Mono';
          font-size: 18px;
          font-weight: 700;
          color: #8797ad;
          margin: 0;
        }
        .wave {
          margin: 0 10px 0 6px;
          width: 24px;
          animation: waving 1s infinite;
          animation-delay: 2s;
          animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }
      }
      .name {
        font-size: 72px;
        font-weight: 900;
        margin: 0 0 34px 0;
        .lastname {
          color: var(--primary);
        }
      }
      .short-intro-wrapper {
        .short-intro {
          font-family: 'Space Mono';
          font-size: 20px;
          color: #757272;
          margin: 0;
        }
        .short-intro-1 {
          display: flex;
          align-items: flex-end;
        }
        .scrolling-wrapper {
          margin: 0 16px;
          height: 36px;
          width: 272px;
          position: relative;
          overflow: hidden;
          .scrolling-elements {
            position: absolute;
            top: 0;
            animation: scrolling 6s infinite;
            .scrolling {
              font-weight: 700;
              font-size: 24px;
              font-family: 'Space Mono';
              color: var(--primary);
              margin: 0;
            }
          }
        }
      }
    }
    .symbol-wrapper {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      bottom: 50px;
      left: 50%;
      transform: translate(-50%);
      text-decoration: none;
      color: var(--text);
      .symbol {
        width: 20px;
        height: 36px;
        border: 2px solid #8797ad;
        border-radius: 12px;
        position: relative;
        top: -4px;
        animation: symbolanim 2s infinite;
        .symbol-dot {
          position: absolute;
          left: 50%;
          top: 8px;
          transform: translate(-50%);
          background-color: #8797ad;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          animation: dotanim 2s infinite;
        }
      }
      .symbol-text {
        font-size: 9px;
        margin-top: 10px;
        font-weight: 800;
      }
    }
  }
}

@keyframes waving {
  0% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}

@keyframes scrolling {
  0% {
    top: 0%;
  }
  24% {
    top: 0%;
  }
  34% {
    top: -92%;
  }
  58% {
    top: -92%;
  }
  68% {
    top: -190%;
  }
  96% {
    top: -190%;
  }
  100% {
    top: 0%;
  }
}

@keyframes dotanim {
  0% {
    top: 8px;
  }
  90% {
    top: 24px;
  }
  100% {
    top: 8px;
  }
}

@keyframes symbolanim {
  0% {
    top: -4px;
  }
  90% {
    top: 0;
  }
  100% {
    top: -4px;
  }
}

/* ABOUT
*******************************************************************/
.about-wrapper {
  background-color: var(--bg1);
  // scroll-padding-top: 84px;
  .about-section {
    min-height: calc(100vh - 100px);
    padding: 60px 32px;
    .about-contents {
      margin: 120px 0;
      display: flex;
      justify-content: space-between;
      .about-text {
        .at-text {
          color: var(--secondary);
          font-family: 'Space Mono';
          font-size: 16px;
          font-weight: 700;
          margin: 0 0 20px 0;
          max-width: 520px;
          .at-highlight {
            color: var(--primary);
          }
        }
        .at-skills-wrapper {
          display: flex;
          flex-wrap: wrap;
          .skill-wrapper {
            height: 28px;
            display: flex;
            align-items: center;
            width: 50%;
            .sk-icon {
              font-size: 6px;
              color: var(--primary);
              transform: rotate(90deg);
              margin-right: 14px;
            }
            .sk-name {
              font-family: 'Space Mono';
              font-size: 13px;
              font-weight: regular;
              color: var(--secondary);
            }
          }
        }
      }
      .about-image {
        margin-right: 16px;
        position: relative;
        width: 320px;
        height: 320px;
        .ai-image {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: 10;
        }
        .ai-outline {
          width: 320px;
          height: 320px;
          border-radius: 8px;
          position: absolute;
          border: 2px solid var(--primary);
          left: 14px;
          top: 14px;
          transition: left 200ms ease, top 200ms ease;
        }
        &:hover {
          .ai-outline {
            left: 4px;
            top: 4px;
          }
        }
      }
    }
  }
}

/* EXPERIENCE
*******************************************************************/
.exp-wrapper {
  --tab-height: 53px;
  .exp-section {
    min-height: calc(100vh - 100px);
    padding: 60px 32px;
  }
  .exp-contents {
    display: flex;
    justify-content: space-between;
    margin: 120px 0;
    .org-menu {
      display: flex;
      flex-direction: column;
      position: relative;
      .org-menu-item {
        background-color: transparent;
        font-family: 'Space Mono';
        font-size: 14px;
        font-weight: 700;
        padding: 16px 36px;
        margin: 0;
        border: none;
        border-left: 2px solid #e0e0e0;
        cursor: pointer;
        transition: background-color 300ms ease;
        &:hover {
          background-color: #fafafa;
        }
      }
      .org-selected {
        background-color: var(--bg1) !important;
        color: var(--primary);
      }
      .selection-indicator {
        position: absolute;
        width: 2px;
        height: var(--tab-height);
        background-color: var(--primary);
        z-index: 10;
        transition: top 400ms ease;
      }
    }
    .org-details {
      max-width: 40%;
      .org-name {
        margin: 0;
        font-size: 16px;
        .org-link {
          color: var(--primary);
          text-decoration: none;
          cursor: pointer;
          position: relative;
          .org-link-bg {
            position: absolute;
            background-color: var(--primary);
            width: 0%;
            height: 1px;
            left: 0;
            bottom: -2px;
            transition: width 300ms ease;
          }
          &:hover {
            .org-link-bg {
              width: 100%;
            }
          }
        }
      }
      .org-duration {
        font-family: 'Space Mono';
        font-size: 13px;
        color: #464646;
        margin: 8px 0 36px 0;
      }
      .od-wrapper {
        .detail-wrapper {
          display: flex;
          margin-bottom: 16px;
          .dw-icon-wrapper {
            margin-right: 14px;
            .dw-icon {
              transform: rotate(90deg);
              color: var(--primary);
              font-size: 6px;
            }
          }
          .dw-name {
            color: var(--secondary);
            font-family: 'Space Mono';
            font-size: 14px;
            margin: 0;
            a {
              text-decoration: none;
              font-weight: 700;
              color: var(--secondary);
              border-bottom: 1px solid var(--secondary);
            }
          }
        }
      }
    }
    .org-skills {
      padding: 24px;
      height: 100%;
      width: 202px;
      background-color: var(--bg1);
      .os-title {
        font-family: 'Inter';
        font-size: 14px;
        margin: 0 0 14px 0;
      }
      .org-skill-item {
        display: flex;
        align-items: center;
        margin: 10px 0;
        .tt-icon {
          transform: rotate(90deg);
          color: var(--primary);
          margin-right: 14px;
          font-size: 6px;
        }
        .tt-name {
          color: var(--secondary);
          font-family: 'Space Mono';
          font-size: 13px;
        }
      }
    }
  }
}

/* PROJECTS
*******************************************************************/
.projects-wrapper {
  background-color: var(--bg1);
  .projects-section {
    min-height: calc(100vh - 100px);
    padding: 60px 32px;
    .projects-contents {
      margin: 120px 0;
      .project-viewer-wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .project-viewer {
          width: 32%;
          background-color: #002b65;
          padding: 36px;
          box-sizing: border-box;
          color: #ffffff;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .pv-header-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .pvh-folder {
              .pvhf-icon {
                font-size: 36px;
                color: #9ec4ff;
              }
            }
            .pvh-links {
              display: flex;
              align-items: center;
              .icon-link {
                margin: 0 4px;
                text-decoration: none;
                color: #ffffff;
                .link-icon {
                  font-size: 20px;
                }
              }
            }
          }
          .pv-title {
            font-size: 18px;
            margin: 24px 0 0 0;
            line-height: 24px;
          }
          .pv-info {
            margin: 18px 0;
            font-size: 15px;
            font-weight: 400;
            color: #9eafc9;
          }
          .pv-tech {
            display: flex;
            align-items: baseline;
            .pvt-tech {
              font-family: 'Space Mono';
              font-size: 13px;
              color: #cccccc;
              &:not(:last-child) {
                &::after {
                  content: '\00B7';
                  margin: 0 6px;
                  color: #ffffff;
                }
              }
            }
          }
        }
      }
      .show-more-button-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 60px 0;
        .show-more-button {
          &:hover {
            background-color: #f4f8ff;
          }
        }
      }
    }
  }
}

/* CONTACTS
*******************************************************************/
.contact-wrapper {
  .contact-section {
    min-height: calc(100vh - 100px);
    padding: 60px 32px;
  }
  .contact-contents {
    margin: 120px auto;
    max-width: 76%;
    .cc-header {
      font-size: 40px;
      font-weight: 800;
      color: var(--secondary);
      text-align: center;
      margin: 0;
    }
    .cc-text {
      text-align: center;
      color: #8895a7;
      font-size: 20px;
      font-weight: 500;
      margin: 36px 0;
      line-height: 24px;
    }
    .say-hello-button-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 60px 0;
      .say-hello-button {
        &:hover {
          background-color: #f4f8ff;
        }
      }
    }
  }
}
