html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Inter', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #ffffff;
}

::-webkit-scrollbar-thumb {
  background-color: #d4daf0;
  border: 2px solid #ffffff;
  border-radius: 10px;
  &:hover {
    background-color: #ced4ec;
  }
}

::selection {
  background: #bed9ff;
  color: #003b91;
}
/* For Mozilla Firefox */
::-moz-selection {
  background: #2971d3;
  color: #003b91;
}

/* CONTAINER
***************************************************************/
.app-container {
  width: 960px;
  margin: 0 auto;
  padding: 0 32px;
}

/* BUTTONS
***************************************************************/
.app-btn {
  background-color: transparent;
  padding: 8px 16px;
  font-size: 15px;
  font-family: 'Space Mono';
  font-weight: 400;
  color: var(--primary);
  border: 1px solid var(--primary);
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 200ms ease;
  text-decoration: none;
  &:hover {
    background-color: var(--light);
  }
}

/* UI ANIMATIONS 
***************************************************************/
.app-appear {
  opacity: 1;
  animation: fade 1.5s linear;
}
@keyframes fade {
  0%,
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadefromtop {
  0% {
    opacity: 0;
    top: -20px;
  }
  80% {
    opacity: 1;
  }
  100% {
    top: 0;
  }
}

/* SECTION HEADERS 
***************************************************************/
.app-section-header {
  position: relative;
  height: 148px;
  .ash-no {
    background-image: linear-gradient(45deg, #ffffff 24%, #2971d3);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Space Mono';
    font-size: 100px;
    font-weight: 700;
    margin: 0;
    position: absolute;
    right: 0;
    user-select: none;
  }
  .ash-title {
    font-size: 42px;
    font-weight: 800;
    margin: 0;
    position: absolute;
    right: 136px;
    bottom: 26px;
  }
}
.app-section-header-blue {
  .ash-no {
    background-image: linear-gradient(45deg, #e7f0fe 24%, #2971d3);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
