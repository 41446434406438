.layout {
  width: 100%;
  position: relative;
  .l-navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 20px 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffffcc;
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    transition: transform 100ms ease, box-shadow 300ms ease;
    z-index: 100;
    .l-logo-wrapper {
      .logo {
        max-width: 50px;
        user-select: none;
      }
    }
    .l-navmenu {
      display: flex;
      align-items: center;
      .menu-item {
        text-decoration: none;
        display: flex;
        align-items: baseline;
        margin-left: 36px;
        position: relative;
        .sr-no {
          font-family: 'Space Mono';
          font-weight: 700;
          font-size: 12px;
          color: var(--primary);
        }
        .name {
          font-weight: 500;
          font-size: 14px;
          margin-left: 4px;
          color: var(--text);
          transition: color 200ms ease;
        }
        &:hover {
          .name {
            color: var(--primary);
          }
        }
      }
      .resume-button {
        margin-left: 48px;
        position: relative;
      }
    }
  }

  .social-icons {
    position: fixed;
    bottom: 0;
    left: 56px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .icon-holder {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 12px;
      .si-btn {
        text-decoration: none;
        color: var(--secondary);
        width: 18px;
        height: 22px;
        position: relative;
        margin-bottom: 20px;
        .icon {
          position: absolute;
          font-size: 20px;
          bottom: 0px;
          transition: bottom 400ms ease;
        }
        &:hover {
          color: var(--primary);
          .icon {
            bottom: 4px;
          }
        }
      }
    }
  }
  .email-contact {
    position: fixed;
    bottom: 0;
    right: 56px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 21px;
    .email-link {
      text-decoration: none;
      color: var(--secondary);
      .email-id {
        width: 21px;
        transform: rotate(90deg);
        font-family: 'Space Mono';
        font-weight: 700;
        font-size: 14px;
        margin: 0 0 190px 0;
        transition: margin 400ms ease;
      }
      &:hover {
        .email-id {
          margin: 0 0 196px 0;
          color: var(--primary);
        }
      }
    }
  }
  .line-design {
    height: 154px;
    width: 1px;
    background-color: var(--secondary);
  }

  .footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    .design-credits {
      font-family: 'Space Mono';
      font-weight: 700;
      font-size: 14px;
      color: var(--secondary);
      margin-bottom: 6px;
      .design-credits-name {
        color: var(--primary);
        text-decoration: none;
        cursor: pointer;
      }
    }
    .copyrights {
      font-size: 12px;
      margin-bottom: 40px;
      color: var(--secondary);
    }
  }
}
