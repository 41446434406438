$primary: #2971d3;
$secondary: #0e3c7b;
$light: #e7f0fe;
$black: #000000;
$white: #ffffff;

:root {
  --primary: #{$primary};
  --secondary: #{$secondary};
  --light: #{$light};
  --text: #{$black};
  --bg: #{$white};
  --bg1: #{$light};
}
